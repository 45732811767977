module.exports = function(KEY){
  var re = /./;
  try {
    '/./'[KEY](re);
  } catch(e){
    try {
      re[require('./$.wks')('match')] = false;
      return !'/./'[KEY](re);
    } catch(f){ /* empty */ }
  } return true;
};