import loadImages from "./image-loader";
import times from "./times.js";
import createCanvas from "./create-canvas.js";
import {random, chance} from "./random";

let minR=10;
let maxR=40;
let deltaR=maxR-minR;
let dropSize=64;
let maxDrops=800;

let Drop={
  x:0,
  y:0,
  r:0,
  spreadX:0,
  spreadY:0,
  momentum:0,
  momentumX:0,
  lastSpawn:0,
  nextSpawn:0,
  parent:null,
  isNew:true,
  killed:false,
  tension:0,
  shrink:0,
}

function Raindrops(w,h){
  this.width=w;
  this.height=h;
  this.init();
}

Raindrops.prototype={
  canvas:null,
  ctx:null,
  width:0,
  height:0,
  texture:null,
  textureCtx:null,
  texturePixelDensity:1,
  drops:null,
  dropsGfx:null,
  paintGfx:null,
  raining:true,
  lastRender:null,
  init(){
    this.canvas = createCanvas(this.width,this.height);
    this.ctx=this.canvas.getContext('2d');

    this.texture = createCanvas(this.width*this.texturePixelDensity,this.height*this.texturePixelDensity);
    this.textureCtx=this.texture.getContext('2d');

    this.drops=[];
    this.newDrops=[];
    this.dropsGfx=[];

    this.renderDropsGfx();
  },
  renderTexture(){
    times(5000,(i)=>{
      this.drawTextureDrop(
        random(this.width),
        random(this.height),
        random(2,4)
      )
    })
  },
  drawTextureDrop(x,y,r){
    // this.textureCtx.globalCompositeOperation="normal";
    this.drawDrop(this.textureCtx,Object.assign(Object.create(Drop),{
      x:x*this.texturePixelDensity,
      y:y*this.texturePixelDensity,
      r:r*this.texturePixelDensity
    }),true);
  },

  renderDropsGfx(){
    loadImages([
      {name:'alpha',src:'img/drop4-alpha4.png'},
      {name:'color',src:'img/drop7.png'}
    ]).then((images)=>{
      let dropBuffer=createCanvas(dropSize,dropSize);
      let dropBufferCtx=dropBuffer.getContext('2d');
      this.dropsGfx=Array.apply(null,Array(255))
        .map((cur,i)=>{
          let drop=createCanvas(dropSize,dropSize);
          let dropCtx=drop.getContext('2d');

          dropBufferCtx.clearRect(0,0,dropSize,dropSize);

          // color
          dropBufferCtx.globalCompositeOperation="normal";
          dropBufferCtx.drawImage(images.color.img,0,0,dropSize,dropSize);

          // blue overlay, for depth
          dropBufferCtx.globalCompositeOperation="screen";
          dropBufferCtx.fillStyle="rgba(0,0,"+i+",1)";
          dropBufferCtx.fillRect(0,0,dropSize,dropSize);

          // alpha
          dropCtx.globalCompositeOperation="normal";
          dropCtx.drawImage(images.alpha.img,0,0,dropSize,dropSize);

          dropCtx.globalCompositeOperation="source-in";
          dropCtx.drawImage(dropBuffer,0,0,dropSize,dropSize);
          return drop;
      });

      this.paintGfx=createCanvas(128,128);
      let paintCtx=this.paintGfx.getContext("2d");
      paintCtx.fillStyle="#000";
      paintCtx.beginPath();
      paintCtx.arc(64,64,64,0,Math.PI*2);
      paintCtx.fill();

      this.renderTexture();
      times(100,(i)=>{
        this.drops.push(
          this.createDrop({
            x:random(this.width),
            y:random(this.height),
            r:random(minR,maxR,(n)=>{
              return n*n*n;
            })
          })
        );
      })

      this.update();
    });
  },
  drawDrop(ctx,drop,normalBlend=false){

    if(this.dropsGfx.length>0){
      let x=drop.x;
      let y=drop.y;
      let r=drop.r;
      let spreadX=drop.spreadX;
      let spreadY=drop.spreadY;

      let scaleX=1;
      let scaleY=1.5;

      let d=Math.max(0,Math.min(1,((r-minR)/(deltaR))*0.9));
      d*=1/(((drop.spreadX+drop.spreadY)*0.5)+1);

      ctx.globalAlpha=1;
      ctx.globalCompositeOperation=normalBlend?"normal":"normal";

      d=Math.floor(d*(this.dropsGfx.length-1));
      ctx.drawImage(
        this.dropsGfx[d],
        x-(r*scaleX*(spreadX+1)),
        y-(r*scaleY*(spreadY+1)),
        r*2*scaleX*(spreadX+1),
        r*2*scaleY*(spreadY+1)
      );

    }
  },
  paint(x,y,r=30){
    let ctx=this.textureCtx;
    ctx.globalCompositeOperation="destination-out";
    ctx.drawImage(
      this.paintGfx,
      (x-r)*this.texturePixelDensity,
      (y-r)*this.texturePixelDensity,
      (r*2)*this.texturePixelDensity,
      (r*2)*this.texturePixelDensity*1.5
    )
  },
  clearCanvas(){
    this.ctx.clearRect(0,0,this.width,this.height);
  },
  createDrop(options){
    if(this.drops.length >= maxDrops) return null;

    return Object.assign(Object.create(Drop),options);
  },
  updateRain(){
    let rainDrops=[];
    if(this.raining){
      let limit=3;
      let count=0;
      while(chance(0.3) && count<limit){
        count++;
        // let r=(minR*0.5)+(Math.pow(Math.random(),3)*(deltaR));
        let r=random(minR,maxR,(n)=>{
          return Math.pow(n,3);
        });
        let rainDrop=this.createDrop({
          x:random(this.width),
          y:random(this.height)-100,
          r:r,
          momentum:1+((r-minR)*0.3)+random(2),
          spreadX:1.5,
          spreadY:1.5,
        });
        if(rainDrop!=null){
          rainDrops.push(rainDrop);
        }
      }
    }
    return rainDrops;
  },
  clearDrops(){
    this.drops.forEach((drop)=>{
      setTimeout(()=>{
        drop.shrink=0.1+(Math.random()*0.5);
      },Math.random()*1200)
    })
  },
  updateTexture(){
    this.ctx.drawImage(this.texture,0,0,this.width,this.height);
    if(this.raining){
      times(50,(i)=>{
        this.drawTextureDrop(
          random(this.width),
          random(this.height),
          random(1.5,4)
        )
      });
    }
  },
  updateDrops(timeScale){
    let newDrops=[];

    let rainDrops=this.updateRain();
    newDrops=newDrops.concat(rainDrops);

    this.drops.sort((a,b)=>{
      let va=(a.y*this.width)+a.x;
      let vb=(b.y*this.width)+b.x;
      return va>vb?1:va==vb?0:-1;
    });

    this.drops.forEach(function(drop,i){
      if(!drop.killed){
        // update gravity
        if(chance((drop.r-minR) * (0.1/deltaR) * timeScale)){
          drop.momentum += random((drop.r/maxR)*4);
        }
        // clean small drops
        if(drop.r<=minR && chance(0.05*timeScale)){
          drop.shrink+=0.01;
        }
        //update shrinkage
        drop.r -= drop.shrink*timeScale;
        if(drop.r<=0) drop.killed=true;

        // update trails
        drop.lastSpawn+=drop.momentum*timeScale;
        if(drop.lastSpawn>drop.nextSpawn){
          let trailDrop=this.createDrop({
            x:drop.x+(random(-drop.r,drop.r)*0.1),
            y:drop.y-(drop.r*0.1),
            r:drop.r*random(0.2,0.5),
            spreadY:drop.momentum*0.1,
            parent:drop,
          });

          if(trailDrop!=null){
            newDrops.push(trailDrop);

            drop.r*=Math.pow(0.97,timeScale);
            drop.lastSpawn=0;
            drop.nextSpawn=random(minR,maxR)-(drop.momentum*2)+(maxR-drop.r);
          }
        }

        //normalize spread
        drop.spreadX*=Math.pow(0.4,timeScale);
        drop.spreadY*=Math.pow(0.7,timeScale);

        //update position
        let moved=drop.momentum>0;
        if(moved && !drop.killed){
          drop.y+=drop.momentum*timeScale;
          drop.x+=drop.momentumX*timeScale;
          if(drop.y>this.height+drop.r){
            drop.killed=true;
          }
        }

        // collision
        let checkCollision=(moved || drop.isNew) && !drop.killed;
        drop.isNew=false;

        if(checkCollision){
          this.drops.slice(i+1,i+70).forEach((drop2)=>{
            //basic check
            if(
              drop != drop2 &&
              drop.r > drop2.r &&
              drop.parent != drop2 &&
              drop2.parent != drop &&
              !drop2.killed
            ){
              let dx=drop2.x-drop.x;
              let dy=drop2.y-drop.y;
              var d=Math.sqrt((dx*dx)+(dy*dy));
              //if it's within acceptable distance
              if(d<(drop.r+drop2.r)*(0.65+(drop.momentum*0.01))){
                let pi=Math.PI;
                let r1=drop.r;
                let r2=drop2.r;
                let a1=pi*(r1*r1);
                let a2=pi*(r2*r2);
                let targetR=Math.sqrt((a1+(a2*0.8))/pi);
                if(targetR>maxR){
                  targetR=maxR;
                }
                drop.r=targetR;
                drop.momentumX+=dx*0.1;
                drop.spreadX=0;
                drop.spreadY=0;
                drop2.killed=true;
                drop.momentum=Math.max(drop2.momentum,Math.min(40,drop.momentum+(targetR*0.04)+1));
              }
            }
          });
        }

        //slowdown momentum
        drop.momentum-=Math.max(1,(minR*0.5)-drop.momentum)*0.1*timeScale;
        if(drop.momentum<0) drop.momentum=0;
        drop.momentumX*=Math.pow(0.7,timeScale);


        if(!drop.killed){
          newDrops.push(drop);
          if(moved) this.paint(drop.x,drop.y,drop.r*0.45);
          this.drawDrop(this.ctx,drop);
        }

      }
    },this);

    this.drops = newDrops;
  },
  update(){
    this.clearCanvas();

    this.updateTexture();

    let now=Date.now();
    if(this.lastRender==null) this.lastRender=now;
    let deltaT=now-this.lastRender;
    let timeScale=deltaT/((1/60)*1000);
    this.lastRender=now;

    this.updateDrops(timeScale);

    requestAnimationFrame(this.update.bind(this));
  }
}

export default Raindrops;
