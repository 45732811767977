import 'core-js';
import WebGLLiquid from "./webgl-liquid";
import Raindrops from "./raindrops";
import loadImages from "./image-loader";
import createCanvas from "./create-canvas";
//import * as shader from "./shaders/water.frag";

let canvas=document.querySelector('#container');
let dpi=1+((window.devicePixelRatio-1)*0.5);
canvas.width=window.innerWidth;
canvas.height=window.innerHeight;
// canvas.style.width=window.innerWidth+"px";
// canvas.style.height=window.innerHeight+"px";
var r=new Raindrops(canvas.width,canvas.height);

loadImages([
  {name:"shine",src:"img/shine6.png"},

  {name:"textureRain",src:"img/texture10.png"},
  {name:"textureRainBlur",src:"img/texture10-blur.png"},

  {name:"textureSun",src:"img/texture8-sun.png"},
  {name:"textureSunBlur",src:"img/texture8-sun-blur.png"},
]).then((images)=>{
  let texture=createCanvas(192/2,128/2);
  let textureCtx=texture.getContext('2d');
  let textureBlur=createCanvas(384,256);
  let textureBlurCtx=textureBlur.getContext('2d');


  function generateTextures(sun=0){
    textureCtx.globalAlpha=1;
    textureCtx.drawImage(images.textureRain.img,0,0,192/2,128/2);
    textureCtx.globalAlpha=sun;
    textureCtx.drawImage(images.textureSun.img,0,0,192/2,128/2);

    textureBlurCtx.globalAlpha=1;
    textureBlurCtx.drawImage(images.textureRainBlur.img,0,0);
    textureBlurCtx.globalAlpha=sun;
    textureBlurCtx.drawImage(images.textureSunBlur.img,0,0);
  }
  generateTextures();

  let liquid=new WebGLLiquid(canvas,r.canvas,images.shine.img, texture, textureBlur);

  let currentSun=0;
  let transition=0;
  canvas.addEventListener('click',(event)=>{
    r.raining=!r.raining;
    r.clearDrops();

    if(!r.raining){
      clearInterval(transition);
      transition=setInterval(()=>{
        currentSun+=0.03;
        if(currentSun>=1){
          currentSun=1;
          clearTimeout(transition);
        }
        generateTextures(currentSun);
        liquid.updateTextures();
      },33);
    }else{
      clearInterval(transition);
      transition=setInterval(()=>{
        currentSun-=0.03;
        if(currentSun<=0){
          currentSun=0;
          clearTimeout(transition);
        }
        generateTextures(currentSun);
        liquid.updateTextures();
      },33);
    }
  });

  canvas.addEventListener('mousemove',(event)=>{

  })
})
