require('./shim');
require('./modules/core.dict');
require('./modules/core.get-iterator-method');
require('./modules/core.get-iterator');
require('./modules/core.is-iterable');
require('./modules/core.delay');
require('./modules/core.function.part');
require('./modules/core.object.is-object');
require('./modules/core.object.classof');
require('./modules/core.object.define');
require('./modules/core.object.make');
require('./modules/core.number.iterator');
require('./modules/core.string.escape-html');
require('./modules/core.string.unescape-html');
require('./modules/core.log');
module.exports = require('./modules/$.core');