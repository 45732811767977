import * as WebGL from "./webgl";
import GL from "./gl-obj";
import loadImages from "./image-loader";
let requireShaderScript = require("glslify");

let vertexShader = requireShaderScript('./shaders/simple.vert');
let fragShaderWater = requireShaderScript('./shaders/water.frag');
let blurShader = requireShaderScript('./shaders/fast-blur.frag');

function WebGLLiquid(canvas,canvasLiquid,imageShine,imageBg,imageBgThrough){

  this.canvas=canvas;
  this.canvasLiquid=canvasLiquid;
  this.imageShine=imageShine;
  this.imageBg=imageBg;
  this.imageBgThrough=imageBgThrough;
  this.init();
}

WebGLLiquid.prototype={
  canvas:null,
  gl:null,
  canvasLiquid:null,
  width:0,
  height:0,
  imageShine:"",
  imageBg:"",
  imageBgThrough:"",
  textures:null,
  programWater:null,
  programBlurX:null,
  programBlurY:null,
  init(){
    this.width=this.canvas.width;
    this.height=this.canvas.height;
    this.gl=new GL(this.canvas, {alpha:false},vertexShader,fragShaderWater);
    let gl=this.gl;
    this.programWater=gl.program;

    gl.createUniform("2f","resolution",this.width,this.height);
    gl.createTexture(null,0);

    this.textures=[
      {name:'shine', img:this.imageShine},
      {name:'texture', img:this.imageBg},
      {name:'textureBlur', img:this.imageBgThrough}
    ];

    this.textures.forEach((texture,i)=>{
      gl.createTexture(texture.img,i+1);
      gl.createUniform("1i",texture.name,i+1);
    });

    this.programBlurY=gl.createProgram(vertexShader,blurShader);
    gl.useProgram(this.programBlurY);

    gl.createUniform("2f","resolution",this.width,this.height);
    gl.createUniform("2f","pixel",1/this.width,1/this.height);
    gl.createUniform("2f","delta",0,1);

    this.programBlurX=gl.createProgram(vertexShader,blurShader);
    gl.useProgram(this.programBlurX);

    gl.createUniform("2f","resolution",this.width,this.height);
    gl.createUniform("2f","pixel",1/this.width,1/this.height);
    gl.createUniform("2f","delta",1,0);

    this.draw();
  },
  draw(){
    this.gl.useProgram(this.programWater);
    this.updateTexture();
    this.gl.draw();

    // this.gl.useProgram(this.programBlurX);
    // this.gl.updateTexture(this.canvas);
    // this.gl.draw();
    //
    // this.gl.useProgram(this.programBlurY);
    // this.gl.updateTexture(this.canvas);
    // this.gl.draw();

    requestAnimationFrame(this.draw.bind(this));
  },
  updateTextures(){
    this.textures.forEach((texture,i)=>{
      this.gl.activeTexture(i+1);
      this.gl.updateTexture(texture.img);
    })
  },
  updateTexture(){
    this.gl.activeTexture(0);
    this.gl.updateTexture(this.canvasLiquid);
  },
  resize(){

  },
  get overlayTexture(){

  },
  set overlayTexture(v){

  }
}

export default WebGLLiquid;
